<template>
  <!-- Foot -->
  <footer>
    <main class="footer-grid">
      <div class="footer-nav">
        <span>business</span>
        <ul>
          <a href="#">
            <li>Enterprise</li>
          </a>
          <a href="#">
            <li>Customers</li>
          </a>
          <a href="#">
            <li>Partners</li>
          </a>
        </ul>
      </div>
      <div class="footer-nav">
        <span>support</span>
        <ul>
          <a href="#">
            <li class="">Speak with Support</li>
          </a>
        </ul>
      </div>
      <div class="footer-nav">
        <span>legal</span>
        <ul>
          <a href="t&c">
            <li>Terms of Service</li>
          </a>
          <a href="privacy">
            <li>Privacy Policy</li>
          </a>
        </ul>
      </div>
      <div class="footer-nav">
        <span>CONTACT</span>
        <ul>
          <li>Block TP Latlin Estate, Estonia</li>
          <a href="mailto:support@cosehans.com">
            <li>support@cosehans.com</li>
          </a>
          <a href="tel:555-666-7777">555-666-7777</a>
          <span>
            <a>
              <img class="social" src="@/assets/icons/social/facebook.svg" />
            </a>
          </span>
          <span>
            <a>
              <img class="social" src="@/assets/icons/social/linkedin.svg" />
            </a>
          </span>
          <span>
            <a>
              <img class="social" src="@/assets/icons/social/instagram.svg" />
            </a>
          </span>
        </ul>
      </div>
    </main>
  </footer>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
/* The footer section */
footer {
  padding: 5em 0px;
  background-color: rgb(241, 241, 241) !important;
}

footer a {
  text-decoration: none;
  display: grid;
}

.social {
  width: 17px;
  margin-right: 20px;
  filter: invert(50%);
}

.footer-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  width: 60%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .footer-grid {
    width: 80%;
  }
}

.footer-nav {
  padding: 20px 0;
  width: 100%;
  line-height: 32px;
}

.footer-nav span {
  font-size: 13px;
  font-weight: 500;
  color: #000000;
  font-family: "Optima";
  font-size: 18px;
  text-transform: uppercase;
}

.footer-nav ul li {
  font-size: 12px;
  font-weight: 300;
  color: #090909;
  list-style-type: none;
}

.footer-nav ul li span {
  color: rgb(63, 63, 63);
  font-size: 9px;
  padding: 3px;
}

span a {
  display: inline;
}

/* The footer section End */
</style>
