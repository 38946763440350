// layouts
import main from "@/layouts/main.vue";

// errors
const error404 = () => import("@/pages/404");

// landing
const Home = () => import("@/pages/home/index.vue");
const Management = () => import("@/pages/management/index.vue");
const Contact = () => import("@/pages/contact/main.vue");

const routes = [
  {
    path: "/",
    props: true,
    component: main,
    children: [
      // landing
      { name: "home", path: "/", component: Home },
      { name: "management", path: "/management", component: Management },
      { name: "contact", path: "/contact", component: Contact },
    ],
  },

  {
    path: "/:catchAll(.*)*",
    component: error404,
  },
];

export default routes;
